
// Variables
$light: #fff;
$dark: #0B3028;
$white: #fff;
$primary-color: #0B3028; //used as highlight for certain text and icons, needs to have contrast with the
$pripmary-darker: #b48203; //not sure
$primary-bg: #FFFF; //background
$border-color:rgba(255, 0, 0, 0.07); //not sure
$black: #c90000; //not sure
$section-bg : #FFFF; //background for get in touch
$primary-font: 'Urbanist', sans-serif;
$secondary-font: 'Urbanist', sans-serif;
$icon-font: 'themefisher-font';
//New Variables
$kalawala-darker-green:#0B3028;
$kalawala-dark-green:#294F44;
$kalawala-light-green:#8AA288;
$kalawala-vivid-beige: #294F44;
$kalawala-opaque-beige: #FFFF;
$kalawala-light-cream: #FFFF;
$kalawala-text-gray: #171717;