@use './mixins';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:300,400,600');
@import  '../styles.scss';

/*=================================================================
  Basic Setup
==================================================================*/
::-moz-selection {
  /* Code for Firefox */
  color: $white;
  background: $primary-color;
}

::selection {
  color: $white;
  background: $primary-color;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  color: $kalawala-dark-green;
  transition: all .3s ease-in 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus,
a:hover {
  color: #6CB670;
}

.kill-margin-bottom {
  margin-bottom: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.btn {
  background-color: transparent;
  color: $light;
  padding: 10px 30px;
  border-radius: 0;
  transition: all .3s ease-in 0s;

  &:focus {
    color: #ddd;
  }
}
.btn-darker {
  background-color: $kalawala-dark-green;
  color: $light;
  padding: 10px 30px;
  border-radius: 0;
}


.btn-transparent {
  border: 1px solid #4e595f;

  &:hover,
  &:focus {
    background-color: $primary-color;
    border: 1px solid transparent;
    color: $light;
  }
}


.form-control {
  background-color: #2f383d;
  border-radius: 0;
  padding: 5px 10px;
  border: 0 none;
  color: #ddd;
}

.form-control:focus {
  box-shadow: 0 0 0;
}

.form-control::-webkit-input-placeholder {
  /*  Chrome, Safari, Opera  */
  color: #ddd;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #ddd;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #ddd;
}

.form-control:-ms-input-placeholder {
  /*  Internet Explorer  */
  color: #ddd;
}

.border {
  border-top: 2px solid rgba(236, 239, 241, 0.07);
  height: 1px;
  margin: 15px auto 0;
  position: relative;
  width: 30%;

  &:before {
    background-color: $light;
    content: '';
    height: 6px;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    top: -4px;
    width: 50px;
    background-color: $primary-color;

  }
}

.color {
  color: #6CB670;
}

.sub-title {
  padding: 0 0 50px;
}

.sub-title>h3 {
  border-left: 3px solid #ddd;
  border-right: 3px solid #ddd;
  display: inline-block;
  padding: 5px 25px;
  text-transform: uppercase;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

.bg-one {
  background-color: $primary-bg;   
}

.b-dark {
  background: $kalawala-dark-green;
}
.b-light {
  background: $kalawala-light-cream; //backround for discover puerto viejo section
  background-image: linear-gradient(to top right, var(--kalawala-opaque-beige) .0001%, var(--kalawala-light-cream) 50%, var(--kalawala-light-cream) 100%);
   
}

.parallax-section {
  background-attachment: fixed !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.no-padding {
  padding: 0 !important;
}

.inline-block {
  display: inline-block;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  transition: all .2s ease-in 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus,
a:hover {
  color: $primary-color;
}


.btn-main {
  background: $primary-color;
  color: $light;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10px 35px;
  text-transform: uppercase;

  &:hover {
    background: darken($primary-color, 20%);
    color: $light;
  }
}

.mt-20 {
  margin-top: 20px;
}

.section {
  padding: 100px 0;

  
  @include mixins.mobile {
    padding: 50px 0;
  }

  @include mixins.tablet {
    padding: 50px 0;
  }
}

.section-sm {
  padding: 70px 0;
}

.section-xs {
  padding: 50px 0;
}

.btn:focus {
  color: #ddd;
}

#home {
  position: relative;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}


.parallax-section {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

// Preloader
.preloader {
  background: $light;
  height: 100%;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $primary-color;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {

  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {

  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}


// Common Styles
.title {
  padding-bottom: 60px;

  h2 {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 38px;
    color: $kalawala-opaque-beige;

    span {
      color: $kalawala-vivid-beige;
    }
  }

  p {
    color: #666;
  }

  &.title-white {
    h2 {
      color: $light;
    }
  }
}


.bg-gray {
  background: #f9f9f9;
}

.section-bg {
  background: $section-bg;
}


.overly {
  position: relative;

  &:before {
    content: '';
    background: rgba(0, 0, 0, 0.096);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}



.header-bradcrumb {
  background: transparent;

  a,
  .active {
    color: #cfcfcf;
    font-weight: 200;
    font-family: $primary-font;
  }

  a:hover {
    color: $primary-color;
  }
}


.slick-slide {
  outline: none;
}

/* CSS mfp-with-zoom class */
// Magnific Popup zoom effect
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.pagination {
  li {
    margin: 5px;
    display: inline-block;

    a {
      background: #242930;
      border: 0;
      color: $white;

      &:hover,
      &.active {
        background: $primary-color;
        color: $white;
      }
    }
  }
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}