@use '../../../styles/mixins';
@import  '../../../styles//styles.scss';

.other-homes-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 250px; // Adjust as needed
    padding: 1rem;
    background: $primary-bg;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  
    .image-container {
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
  
      .home-image {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  
    .content {
      margin-top: 10px;
  
      .home-name {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 5px;
      }
  
      .icons {
        display: flex;
        justify-content: center;
        gap: 10px;
        font-size: 1.2rem;
  
        .icon-group {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  
    &:hover {
      transform: translateY(-5px);
    }
  }
  