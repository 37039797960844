@use '../../../../styles/mixins';
@import '../../../../styles/styles.scss';



.cont {
    height: 100%;
    padding-top: 60px;
    padding-bottom: 20px;
    flex-direction: column;

    .listing:hover {
        cursor: pointer;
        transform: translateY(-10px);
        box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.363);
    }

}

.listing {
    background-color: rgb(25, 136, 136);
    height: 170px;
    width: 230px;
    border-radius: 5px;
    flex-direction: row;
    border: 3px solid $primary-color;
    background-size: cover;
    background-position: center center;

    @media (max-width: 1440px) {
        width: 100%;
    }

}

.name {
    background-color: #303233ab;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 26px;
    text-align: center;
    color: white;
    font-size: 16px;
}

.subCont {
    width: 100%;
    align-items: center;
}

.header {
    text-align: center;
    color: $kalawala-text-gray ;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.adContainer {
    max-width: 1200px;
    margin: auto;
    padding: 20px;

    .header {
        text-align: center;
        margin-bottom: 20px;
    }

    .subCont {
        width: 100%;

        .houseContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .listing {
                background-size: cover;
                background-position: center;
                height: 250px; // Ensure this matches the desired height of your images
                position: relative;
                margin-bottom: 20px;
                width: 95%;
                cursor: pointer;
                .name {
                    background-color: rgba(0, 0, 0, 0.5);
                    color: white;
                    width: 100%;
                    text-align: center;
                    padding: 10px;
                    font-size: 1.2rem;
                }
            }
        }
    }
}