/*
Template Name: Meghna Responsive HTML5/CSS3 Business Template
Author: Themefisher
Author URI: www.Themefisher.com
Description: Meghna Responsive HTML5/CSS3 Portfolio Template
Version: 1.0
Tags: one-page, single-page, portfolio, custom-colors, post-formats, responsive, html5, css3
*/


/*=== MEDIA QUERY ===*/

@import url("https://fonts.googleapis.com/css?family=Anaheim|Quattrocento+Sans:400,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:300,400,600");
@import "_variables.css";


html {
    background-color: #353b43;
    color: #737f8a;
}

body {
    background-color: #353b43;
    font-family: "Anaheim", sans-serif;
    color: #737f8a;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    font-family: "Quattrocento Sans", sans-serif;
    color: #afbac4;
}

p {
    font-family: "Quattrocento Sans", sans-serif;
}


/*=================================================================
  Basic Setup
==================================================================*/

::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: #57cbcc;
}

::selection {
    color: #fff;
    background: #57cbcc;
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

figure {
    margin: 0;
}

a {
    color: #fff;
    transition: all .3s ease-in 0s;
}

iframe {
    border: 0;
}

a,
a:focus,
a:hover {
    text-decoration: none;
    outline: 0;
}

a:focus,
a:hover {
    color: #6CB670;
}

.kill-margin-bottom {
    margin-bottom: 0 !important;
}

.padding-0 {
    padding: 0 !important;
}

.btn {
    background-color: transparent;
    color: #fff;
    padding: 10px 30px;
    border-radius: 0;
    transition: all .3s ease-in 0s;
}

.btn:focus {
    color: #ddd;
}

.btn-transparent {
    border: 1px solid #4e595f;
}

.btn-transparent:hover,
.btn-transparent:focus {
    background-color: #57cbcc;
    border: 1px solid transparent;
    color: #fff;
}

.form-control {
    background-color: #2f383d;
    border-radius: 0;
    padding: 5px 10px;
    border: 0 none;
    color: #ddd;
}

.form-control:focus {
    box-shadow: 0 0 0;
}

.form-control::-webkit-input-placeholder {
    /*  Chrome, Safari, Opera  */
    color: #ddd;
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #ddd;
}

.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #ddd;
}

.form-control:-ms-input-placeholder {
    /*  Internet Explorer  */
    color: #ddd;
}

.border {
    border-top: 2px solid rgba(236, 239, 241, 0.07);
    height: 1px;
    margin: 15px auto 0;
    position: relative;
    width: 30%;
}

.border:before {
    background-color: #fff;
    content: '';
    height: 6px;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    top: -4px;
    width: 50px;
    background-color: #57cbcc;
}

.color {
    color: #6CB670;
}

.sub-title {
    padding: 0 0 50px;
}

.sub-title>h3 {
    border-left: 3px solid #ddd;
    border-right: 3px solid #ddd;
    display: inline-block;
    padding: 5px 25px;
    text-transform: uppercase;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
}

.bg-one {
    background-color: #353b43;
}

.bg-dark {
    background: var(--kalawala-light-cream);
}

.bg-light {
    background: var(--kalawala-light-cream);
}

.parallax-section {
    background-attachment: fixed !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.no-padding {
    padding: 0 !important;
}

.inline-block {
    display: inline-block;
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

figure {
    margin: 0;
}

a {
    transition: all .2s ease-in 0s;
}

iframe {
    border: 0;
}

a,
a:focus,
a:hover {
    text-decoration: none;
    outline: 0;
}

a:focus,
a:hover {
    color: #57cbcc;
}

.btn-main {
    background: #57cbcc;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 10px 35px;
    text-transform: uppercase;
}

.btn-main:hover {
    background: #2c9091;
    color: #fff;
}

.mt-20 {
    margin-top: 20px;
}

.section {
    padding: 100px 0;
}

@media (max-width: 480px) {
    .section {
        padding: 50px 0;
    }
}

@media (max-width: 768px) {
    .section {
        padding: 50px 0;
    }
}

.section-sm {
    padding: 70px 0;
}

.section-xs {
    padding: 50px 0;
}

.btn:focus {
    color: #ddd;
}

#home {
    position: relative;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
}

.parallax-section {
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.preloader {
    background: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sk-cube-grid {
    width: 40px;
    height: 40px;
}

.sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #57cbcc;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {

    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }

    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {

    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }

    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

.title {
    padding-bottom: 60px;
}

.title h2 {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 38px;
    color: #fff;
}

.title h2 span {
    color: #57cbcc;
}

.title p {
    color: #666;
}

.title.title-white h2 {
    color: #fff;
}

.bg-gray {
    background: #f9f9f9;
}

.section-bg {
    background: #292F36;
}

.overly {
    position: relative;
}

.overly:before {
    content: '';
    background: rgba(0, 0, 0, 0.096);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.header-bradcrumb {
    background: transparent;
}

.header-bradcrumb a,
.header-bradcrumb .active {
    color: #cfcfcf;
    font-weight: 200;
    font-family: "Quattrocento Sans", sans-serif;
}

.header-bradcrumb a:hover {
    color: #57cbcc;
}

.slick-slide {
    outline: none;
}


/* CSS mfp-with-zoom class */

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.pagination li {
    margin: 5px;
    display: inline-block;
}

.pagination li a {
    background: #242930;
    border: 0;
    color: #fff;
}

.pagination li a:hover,
.pagination li a.active {
    background: #57cbcc;
    color: #fff;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.logo {
    margin-top: 10px;
    display: inline-block;
}

.single-page-header {
    /* background-image: url("../images/about/about-header.jpg"); */
    background-size: cover;
    padding: 140px 0 70px;
    text-align: center;
    color: #fff;
    position: relative;
}

.single-page-header:before {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}


/*=================================================================
  Navigation
==================================================================*/

.navigation {
    background-color: rgba(189, 169, 109, 0);
    /* Light cream color with 50% transparency */
    width: 100%;
    z-index: 99;
    margin-bottom: 0;
    padding-bottom: 40px;
    padding-top: 20px;
    background-image: linear-gradient(to bottom right, var(--kalawala-opaque-beige) 20%, rgba(255, 248, 240, 0) 50%, rgba(255, 248, 240, 0) 100%);
    /*gradient of the menu*/
}

.navbar-toggler {
    color: var(--kalawala-darker-green) !important;
}


.navigation .logo {
    padding: 0;
    height: auto;
    margin-top: 10;
}

.navigation .logo img {
    height: auto;
    width: 140px;
}


.navigation .navigation-menu li a {
    font-size: 16px;
    color: #737f8a;
}

.navigation .navigation-menu li a:hover,
.navigation .navigation-menu li a:focus {
    border-color: transparent;
    background: transparent;
    color: #57cbcc;
}

.navbar-toggle {
    border-radius: 0;
}

.navbar-toggle span {
    background: #fff;
}

.bg-1 {
    /* background-image: url("../images/backgrounds/bg-1.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-2 {
    /* background-image: url("../images/backgrounds/bg-2.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.hero-area {
    background-size: cover;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.hero-area .block {
    text-align: center;
    z-index: 99;
}

.hero-area .block .video-button a {
    background: #fff;
    display: inline-block;
    height: 60px;
    width: 60px;
    border-radius: 50px;
    line-height: 60px;
    font-size: 20px;
    margin-left: 3px;
    color: #57cbcc;
}

.hero-area .block h1 {
    font-size: 90px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 20px;
}

@media (max-width: 480px) {
    .hero-area .block h1 {
        font-size: 60px;
    }
}

.hero-area .block p {
    color: #fff;
    font-size: 20px;
    width: 70%;
    margin: 0 auto;
}

.hero-area .block .btn-transparent {
    margin-top: 40px;
    border-color: #fff;
    padding: 14px 50px;
    font-size: 18px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}

.hero-video {
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.overlay {
    position: relative;
}

.overlay::before {
    content: '';
    background: rgba(0, 0, 0, 0.63);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    left: 0;
}

.dark-bg {
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
}


/*=================================================================
  About us section
==================================================================*/

.about .block {
    background: #006aff;
    padding: 30px;
    border-bottom: 2px solid transparent;
    transition: .5s all;
    margin-bottom: 20px;
}

.about .block:hover {
    cursor: pointer;
    border-bottom: 2px solid #57cbcc;
}

.about .block:hover .content .highlight {
    color: var(--kalawala-darker-green) !important;
}

.about .block:hover .content .border-highlight {
    border-color: var(--kalawala-darker-green) !important;
}

.about .block:hover .icon-box {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.about .block .icon-box {
    position: relative;
    height: 92.38px;
    background-color: transparent;
    margin: 46.19px auto 0px;
    padding: 20px 0;
    /* border-left: 2px solid rgba(236, 239, 241, 0.07);
    border-right: 2px solid rgba(236, 239, 241, 0.07); */
    font-size: 50px;
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    transition-duration: 0.3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}


/* .about .block .icon-box:after,
.about .block .icon-box:before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 113.14px;
    height: 113.14px;
    background-color: inherit;
    left: 20.4315px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
} */


/* .about .block .icon-box:before {
    top: -56.5685px;
    border-top: 2px solid rgba(236, 239, 241, 0.07);
    border-right: 2px solid rgba(236, 239, 241, 0.07);
}

.about .block .icon-box:after {
    bottom: -56.5685px;
    border-bottom: 2px solid rgba(236, 239, 241, 0.07);
    border-left: 2px solid rgba(236, 239, 241, 0.07);
} */

.about .block .icon-box h3 {
    color: var(--kalawala-darker-gray);
}


/*=================================================================
  About us 2 section
==================================================================*/

.about-2 .content-block {
    padding: 40px;
}

.about-2 .content-block .media {
    margin-top: 25px;
}

.about-2 .content-block .media .pull-left {
    padding-right: 15px;
}

.about-2 .content-block .media i {
    font-size: 30px;
    color: #57cbcc;
}

.about-2 .content-block .media h4 {
    font-size: 16px;
    font-weight: 600;
}


/*=================================================================
 Call To Action 1
==================================================================*/

.call-to-action {
    background-attachment: fixed;
}

.call-to-action h2 {
    font-weight: 700;
    color: #444;
}

.call-to-action p {
    font-size: 16px;
    color: #666;
}

.call-to-action .btn-main {
    margin-top: 20px;
}


/*=================================================================
 Call To Action 2
==================================================================*/

.call-to-action-2 {
    padding: 100px 0;
    /* background-image: url("../images/call-to-action/call-to-action-bg.jpg"); */
    background-size: cover;
    color: #fff;
}

.call-to-action-2 h2 {
    line-height: 1.5;
}

.call-to-action-2 p {
    color: #fff;
}

.call-to-action-2 .btn-main {
    margin-top: 20px;
}

.contact-us {
    padding-top: 50px;
}

.contact-form {
    margin-bottom: 60px;
}

.contact-form .form-control {
    background-color: transparent;
    border: 1px solid rgba(236, 239, 241, 0.07);
    height: 45px;
}

.contact-form textarea.form-control {
    padding: 10px;
    height: 120px;
}

.contact-form input:hover,
.contact-form textarea:hover,
#contact-submit:hover {
    border-color: #57cbcc;
}

#contact-submit {
    border: 1px solid rgba(236, 239, 241, 0.07);
    background: #57cbcc;
    padding: 12px 0;
    width: 100%;
    margin: 0;
}

.google-map #map-canvas {
    height: 400px;
}

.contact-info p {
    margin-bottom: 25px;
}

.con-info {
    margin-bottom: 20px;
}

.con-info i,
.con-info span {
    float: left;
}

.con-info span {
    margin: -5px 0 0 15px;
}

.error {
    display: none;
    padding: 10px;
    color: #D8000C;
    border-radius: 4px;
    font-size: 13px;
    background-color: #FFBABA;
}

.success {
    background-color: #6cb670;
    border-radius: 4px;
    color: #fff;
    display: none;
    font-size: 13px;
    padding: 10px;
}

#map {
    height: 370px;
    width: 100%;
}


/*=================================================================
  Item counter section
==================================================================*/

.counters-item {
    padding: 25px 0;
    margin-bottom: 10px;
    background: #292F36;
}

.counters-item i {
    margin-bottom: 10px;
    font-size: 40px;
    display: inline-block;
}

.counters-item span {
    display: block;
    font-weight: bold;
    font-size: 35px;
    line-height: 50px;
}

.counters-item h3 {
    font-size: 20px;
    margin: 0;
}


/*=================================================================
  Pricing section
==================================================================*/

.price-item {
    border: 1px solid rgba(236, 239, 241, 0.07);
    padding-bottom: 30px;
    margin-bottom: 15px;
}

.price-title {
    background-color: #292F36;
    padding: 30px 0 20px;
}

.price-title h3 {
    color: #57cbcc;
    margin: 0 0 10px;
    text-transform: uppercase;
}

.price-title p {
    font-size: 18px;
    font-weight: 400;
}

.price-title .value {
    color: #57cbcc;
    font-size: 30px;
}

.pricing li {
    padding: 10px 0;
}

.pricing li:last-child {
    border-bottom: 1px solid rgba(236, 239, 241, 0.07);
    margin-bottom: 20px;
}

.pricing li:nth-child(2n) {
    background-color: #292F36;
}

.pricing li .btn {
    text-transform: uppercase;
    margin: 20px 0;
}


/*=================================================================
  Portfolio section
==================================================================*/

.portfolio-filter {
    margin-bottom: 40px;
    text-align: center;
}

.portfolio-filter button {
    border: none;
    outline: none;
    border: 1px solid rgba(236, 239, 241, 0.07);
    background: transparent;
    display: inline-block;
    font-size: 16px;
    padding: 6px 20px;
    font-weight: 700;
    color: #737f8a;
    transition: .3s all;
}

.portfolio-filter button.active {
    border: 1px solid #57cbcc;
    color: #57cbcc;
}

.portfolio-items-wrapper .mix {
    padding: 5px;
}

.portfolio-block {
    position: relative;
    margin-bottom: 20px;
}

.portfolio-block:before {
    transition: all 0.3s ease-in-out;
    background: white;
    opacity: 0;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    position: absolute;
    content: '';
}

.portfolio-block img {
    width: 100%;
    height: auto;
}

.portfolio-block:hover:before {
    opacity: 1;
}

.portfolio-block:hover .caption h4 {
    top: 45%;
}

.portfolio-block:hover .caption {
    opacity: 1;
    top: 55%;
}

.portfolio-block .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9;
    transition: all 0.2s ease-in-out;
    text-align: center;
}

.portfolio-block .caption h4 {
    font-size: 16px;
    font-weight: 600;
}

.portfolio-block .caption h4 a {
    color: #555;
}

.portfolio-block .caption .search-icon {
    background: #57cbcc;
    width: 50px;
    height: 50px;
    color: #fff;
    display: inline-block;
    font-size: 20px;
    border-radius: 30px;
}

.portfolio-block .caption .search-icon i {
    line-height: 50px;
}


/*=================================================================
  Services section
==================================================================*/

.service-block {
    padding: 60px 20px;
    border-left: 1px solid rgba(236, 239, 241, 0.07);
    border-top: 1px solid rgba(236, 239, 241, 0.07);
    margin-bottom: 40px;
    box-shadow: 2px 2px 5px rgba(44, 44, 44, 0.3), 13px 13px 0 rgba(255, 255, 255, 0.7);
}

.service-block .service-icon {
    margin-bottom: 10px;
    font-size: 50px;
}


/*=================================================================
  Services section 2
==================================================================*/

.service-2 .service-item {
    border: 1px solid #eee;
    margin-bottom: 30px;
    padding: 50px 20px;
    transition: all 0.3s ease 0s;
}

.service-2 .service-item:hover {
    box-shadow: 0 5px 65px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 5px 65px 0 rgba(0, 0, 0, 0.15);
}

.service-2 .service-item:hover i {
    background: #fff;
    color: #57cbcc;
}

.service-2 .service-item i {
    font-size: 30px;
    display: inline-block;
    background: #57cbcc none repeat scroll 0 0;
    border-radius: 30px;
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
    color: #fff;
    height: 55px;
    line-height: 55px;
    margin-bottom: 20px;
    width: 55px;
    transition: all 0.3s ease 0s;
}


/*=================================================================
  Our skills
==================================================================*/

.skill-bar p {
    font-size: 18px;
    margin-bottom: 0;
}

.skill-bar p span {
    font-style: italic;
    font-size: 30px;
    color: rgba(187, 187, 187, 0.34);
    display: inline-block;
    vertical-align: middle;
}

.skill-bar .progress {
    height: 6px;
    border-radius: 0;
}

.skill-bar .progress .progress-bar {
    background: #57cbcc;
}


/*=================================================================
  Our Team
==================================================================*/

.team-member {
    background-color: #292F36;
    margin-bottom: 10px;
}

.team-member:hover .mask {
    cursor: pointer;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: rotateY(0deg) scale(1, 1);
    transform: rotateY(0deg) scale(1, 1);
}

.member-photo {
    overflow: hidden;
    position: relative;
}

@media (max-width: 768px) {
    .member-photo {
        text-align: center;
    }
}

.member-photo:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.member-photo img {
    transition: all 0.4s ease 0s;
}

@media (max-width: 768px) {
    .member-photo img {
        display: inline-block;
    }
}

.member-photo .mask {
    background-color: rgba(87, 203, 204, 0.7);
    position: absolute;
    text-align: center;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    transition: all .5s ease-out 0s;
    -webkit-transform: rotateY(180deg) scale(0.5, 0.5);
    transform: rotateY(180deg) scale(0.5, 0.5);
}

.member-photo .mask ul {
    text-align: center;
    position: relative;
    top: 125px;
}

.member-photo .mask ul li a {
    border: 1px solid #fff;
    color: #fff;
    display: block;
    font-size: 20px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    width: 35px;
}

.member-photo .mask ul li a:hover {
    background-color: #fff;
    color: #57cbcc;
    border: 1px solid transparent;
}

.member-meta {
    padding: 15px;
}

.member-meta h4 {
    margin: 0 0 5px;
}

.member-meta span {
    font-size: 12px;
}


/*=================================================================
  Testimonial section
==================================================================*/

.testimonial:before {
    background: rgba(42, 48, 55, 0.7);
}

.client-info {
    margin: 0 auto;
    width: 45%;
}

@media (max-width: 768px) {
    .client-info {
        width: 90%;
    }
}

.client-comment p {
    color: #afbac4;
    font-size: 18px;
}

.client-thumb img {
    border-radius: 50%;
    margin: 0 auto;
    max-width: 115px;
    width: 100%;
}

.client-meta {
    margin: 0 0 10px;
}

.client-meta h3 {
    margin: 15px 0 5px .0;
}

.client-meta span {
    font-size: 12px;
}

.owl-pagination,
.owl-buttons {
    text-align: center;
}

.owl-controls .owl-page {
    display: inline-block;
}

.owl-controls .owl-page span {
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 50px;
    display: block;
    height: 13px;
    margin: 0 4px;
    width: 13px;
    opacity: 1;
    filter: alpha(opacity=100);
}

.owl-controls .owl-page.active span,
.owl-controls .owl-page:hover span {
    background-color: #57cbcc;
    border: 2px solid transparent;
}

.our-clients {
    margin: 30px 0 0;
}

#our-clietns .item img {
    max-width: 150px;
    width: 100%;
}

#our-clietns .owl-next,
#our-clietns .owl-prev {
    border: 1px solid #ddd;
    border-radius: 50px;
    display: inline-block;
    margin: 45px 0 0;
    padding: 3px 15px;
    position: relative;
    text-align: center;
}

#our-clietns .owl-next i,
#our-clietns .owl-prev i {
    line-height: 34px;
}

#our-clietns .owl-prev {
    margin-right: 15px;
}

#our-clients .item {
    margin: 10px;
}


/*=================================================================
  Latest Posts
==================================================================*/

.note {
    background-color: #292F36;
    border: 1px solid #4e595f;
}

.post-block {
    background-color: #292F36;
    margin-bottom: 10px;
}

.post-block .content {
    padding: 20px;
}

.post-block .content h3 {
    margin: 0 0 6px;
    font-size: 20px;
    line-height: 1.5;
    text-transform: capitalize;
}

.note .media-wrapper {
    border-bottom: 1px solid #4e595f;
}

.note .excerpt h3 {
    font-size: 20px;
    line-height: 1.5;
    text-transform: capitalize;
}

.all-post {
    margin-top: 50px;
}


/*=================================================================
  Single Blog Page
==================================================================*/

#blog-banner {
    padding-bottom: 100px;
    padding-top: 150px;
}

#blog-page {
    margin: 0 0 40px;
}

.post-meta {
    font-size: 13px;
    margin: 20px 0 0;
    padding: 0 20px 10px;
}

.post-meta a {
    color: #57cbcc;
}

.post-meta>span {
    color: #666;
    border-right: 1px solid #dedede;
    display: inline-block;
    font-size: 13px;
    margin: 10px 10px 0 0;
    padding-right: 10px;
}

.post-meta>span:last-child {
    border-right: 0;
}

.post-meta>span i {
    margin-right: 5px;
}

.post-excerpt {
    padding: 0 20px;
    margin-bottom: 60px;
}

.post-excerpt h3 a {
    color: #000;
}

.post-excerpt blockquote {
    line-height: 22px;
    margin: 20px 0;
    font-size: 16px;
}

.single-blog {
    background-color: #fff;
    margin-bottom: 50px;
    padding: 20px;
}

.blog-subtitle {
    font-size: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dedede;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.next-prev {
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;
    margin: 20px 0;
    padding: 25px 0;
}

.next-prev a {
    color: #000;
}

.next-prev a:hover {
    color: #57cbcc;
}

.next-prev .prev-post i {
    margin-right: 10px;
}

.next-prev .next-post i {
    margin-left: 10px;
}

.social-profile ul li {
    margin: 0 10px 0 0;
    display: inline-block;
}

.social-profile ul li a {
    color: #4e595f;
    display: block;
    font-size: 16px;
}

.social-profile ul li a i:hover {
    color: #57cbcc;
}

.comments-section {
    margin-top: 35px;
}

.author-about {
    margin-top: 40px;
}

.post-author {
    margin-right: 20px;
}

.post-author>img {
    border: 1px solid #dedede;
    max-width: 120px;
    padding: 5px;
    width: 100%;
}

.comment-list ul {
    margin-top: 20px;
}

.comment-list ul li {
    margin-bottom: 20px;
}

.comment-wrap {
    border: 1px solid #dedede;
    border-radius: 1px;
    margin-left: 20px;
    padding: 10px;
    position: relative;
}

.comment-wrap .author-avatar {
    margin-right: 10px;
}

.comment-wrap .media .media-heading {
    font-size: 14px;
    margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
    color: #57cbcc;
    font-size: 13px;
}

.comment-wrap .media .comment-meta {
    font-size: 12px;
    color: #888;
}

.comment-wrap .media p {
    margin-top: 15px;
}

.comment-reply-form {
    margin-top: 80px;
}

.comment-reply-form input,
.comment-reply-form textarea {
    height: 35px;
    border-radius: 0;
    box-shadow: none;
}

.comment-reply-form input:focus,
.comment-reply-form textarea:focus {
    box-shadow: none;
    border: 1px solid #57cbcc;
}

.comment-reply-form textarea,
.comment-reply-form .btn-main {
    height: auto;
}


/*=================================================================
  Footer section
==================================================================*/

.social-icon {
    padding: 60px 0 35px;
}

.social-icon ul {
    text-align: center;
}

.social-icon ul li {
    margin-bottom: 4px;
}

.social-icon ul li:hover a {
    background-color: #57cbcc;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.social-icon ul li:hover a i {
    color: #fff;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.social-icon ul li a {
    background-color: #26292e;
    border: 1px solid transparent;
    display: block;
    height: 70px;
    width: 70px;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    transition: all 400ms ease-out 0s;
}

@media (max-width: 768px) {
    .social-icon ul li a {
        width: 55px;
        height: 55px;
    }
}

.social-icon ul li a i {
    color: #55606a;
    display: inline-block;
    font-size: 32px;
    line-height: 70px;
    margin: 0;
    opacity: 0.3;
    filter: alpha(opacity=30);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    transition: all 400ms ease-out 0s;
}

@media (max-width: 768px) {
    .social-icon ul li a i {
        line-height: 55px;
        font-size: 25px;
    }
}

.copyright {
    padding: 20px 0;
    color: #fff;
}

.copyright img {
    margin-bottom: 15px;
}

.copyright p {
    margin-top: 10px;
}

#scrollUp {
    border: 1px solid #4e595f;
    bottom: 15px;
    color: #fff;
    display: none;
    padding: 8px 12px;
    position: fixed;
    right: 20px;
    z-index: 600;
}

#scrollUp:hover {
    background-color: #57cbcc;
    border: 1px solid #57cbcc;
}


/*=================================================================
	Twitter Feed
==================================================================*/

#twitter-feed {
    /* background-image: url("../images/parallax/twitter.jpg"); */
    padding-bottom: 100px;
    padding-top: 80px;
}

.twitter-bird span {
    border: 2px solid #6cb670;
    border-radius: 50px;
    color: #6cb670;
    display: inline-block;
    margin-bottom: 50px;
    height: 90px;
    width: 90px;
}

.twitter-bird span i {
    line-height: 90px;
}

.tweet {
    margin-bottom: 10px;
}

.tweet a {
    color: #6cb670;
}


/*=================================================================
	Blog Page
==================================================================*/

#blog-banner {
    padding-bottom: 100px;
    padding-top: 150px;
}

.blog-icon {
    border: 1px solid #6CB670;
    border-radius: 50%;
    color: #6CB670;
    display: inline-block;
    padding: 16px 20px;
}

.blog-title h1 {
    text-transform: uppercase;
}

#blog-page {
    margin: 0 0 40px;
}

.post-item .entry {
    border: 1px solid #4e595f;
    margin-bottom: 30px;
}

.entry .post-excerpt {
    padding: 0 20px;
}

#gallery-post .owl-prev,
#post-thumb .owl-prev,
#gallery-post .owl-next,
#post-thumb .owl-next {
    padding: 5px 17px;
    position: absolute;
    top: 45%;
    transition: all .5s ease-in 0s;
}

#gallery-post .owl-prev,
#post-thumb .owl-prev {
    left: -45px;
}

#gallery-post .owl-next,
#post-thumb .owl-next {
    right: -45px;
}

#gallery-post:hover .owl-prev,
#post-thumb:hover .owl-prev {
    left: 0;
}

#gallery-post:hover .owl-next,
#post-thumb:hover .owl-next {
    right: 0;
}

#gallery-post,
#post-thumb {
    overflow-x: hidden;
}

#post-thumb .owl-buttons {
    color: #fff;
}

.post-meta {
    background-color: #3d444a;
    border-top: 1px solid #4e595f;
    font-size: 13px;
    margin: 20px 0 0;
    padding: 0 20px 10px;
}

.post-meta>span {
    border-right: 1px solid #4e595f;
    display: inline-block;
    font-size: 12px;
    margin: 10px 10px 0 0;
    padding-right: 10px;
}

.post-meta>span:last-child {
    border-right: 0;
}

.post-meta>span>i {
    margin-right: 5px;
}

.post-meta a:hover {
    color: #6CB670;
}

.post-excerpt .more {
    margin-top: 20px;
}

.post-excerpt .more a {
    display: inline-block;
    font-size: 13px;
    padding: 10px 25px;
}

.post-excerpt .more a:hover {
    background-color: #6CB670;
    color: #fff;
}

aside.widget {
    margin-bottom: 25px;
}

.widget-title>h3 {
    color: #fff;
    margin-bottom: 15px;
    margin-top: 0;
    position: relative;
}

#search-form {
    position: relative;
}

#search-submit {
    background: none;
    border: 0;
    color: #C8C8CA;
    display: block;
    font-size: 16px;
    height: 32px;
    outline: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 32px;
}

.widget-content .tab-content {
    background-color: #2f383d;
    padding: 20px;
}

.tab-post-nav {
    background-color: #4A535A;
}

.tab-post-nav li {
    float: left;
}

.tab-post-nav li.active a {
    border-top: 3px solid #6cb670;
    background-color: #2F383D;
    color: #6CB670;
}

.tab-pane.active .clearfix:first-child {
    margin-bottom: 25px;
}

.tab-excerpt>span {
    display: block;
    font-size: 12px;
    margin-bottom: 10px;
}

.tab-excerpt h4 a {
    color: #fff;
}

.tab-excerpt h4 a:hover {
    color: #6cb670;
}

.tab-post-nav li a {
    border-top: 3px solid transparent;
    color: #fff;
    display: block;
    padding: 13px 18px;
    text-align: center;
}

.categories li {
    margin-bottom: 10px;
    transition: all .5s ease-in 0s;
}

.categories>li:hover a {
    padding-left: 10px;
    color: #6CB670;
}

.categories>li:last-child {
    border-bottom: 0;
}

.categories li a {
    padding-left: 5px;
}

.widget-content .tag {
    background-color: #2F383D;
    color: #fff;
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 5px;
    padding: 5px 13px;
}

.widget-content .tag:hover {
    background-color: #6CB670;
    color: #fff;
}

.post-pagination {
    display: inline-block;
    margin-bottom: 20px;
}

.post-pagination ul li {
    margin-right: 5px;
    float: left;
}

.post-pagination ul li:last-child {
    margin-right: 0;
}

.post-pagination ul li.disabled a {
    cursor: not-allowed;
}

.post-pagination ul li.disabled a:hover {
    background-color: #2E373C;
    color: #9aa8b3;
}

.post-pagination ul li a {
    background-color: #30393e;
    color: #9aa8b3;
    display: block;
    padding: 8px 18px;
}

.post-pagination ul li a:hover,
.post-pagination ul li.active a {
    background-color: #6CB670;
    color: #fff;
}


/*=================================================================
	Single Blog Page
==================================================================*/

.single-blog {
    background-color: #fff;
    margin-bottom: 50px;
    padding: 20px;
}

blockquote {
    border-left: 5px solid #6cb670;
    font-style: italic;
    line-height: 22px;
    margin: 20px 0;
}

.next-prev {
    border-bottom: 1px solid #4e595f;
    border-top: 1px solid #4e595f;
    margin: 20px 0;
    padding: 15px 0 10px;
}

.next-prev a:hover {
    color: #6CB670;
}

.prev-post i {
    float: left;
    margin-right: 10px;
    margin-top: -4px;
}

.next-post i {
    float: right;
    margin-left: 10px;
    margin-top: -3px;
}

.post-author {
    margin-right: 20px;
}

.post-author>img {
    border: 1px solid #4e595f;
    max-width: 120px;
    padding: 5px;
    width: 100%;
}

.comment-reply-form>h3,
.author-about>h4,
.comments>h4 {
    border-bottom: 1px solid #4e595f;
    margin-bottom: 15px;
    margin-top: 0;
    padding-bottom: 10px;
    position: relative;
}

.author-bio h5 {
    display: inline-block;
}

.post-item .comments-section {
    margin-top: 35px;
}

.comment-list ul {
    margin-top: 20px;
}

.comment-list li {
    margin-bottom: 20px;
}

.comment-wrap {
    border: 1px solid #4e595f;
    border-radius: 1px;
    margin-left: 20px;
    padding: 10px;
    position: relative;
}

.comment-wrap .author-avatar {
    margin-left: -25px;
    margin-right: 20px;
}

.author-comment {
    margin-bottom: 15px;
    overflow: hidden;
}

.author-comment cite a:hover,
.replay:hover {
    color: #6CB670;
    text-decoration: underline;
}

.author-comment cite {
    margin-bottom: 2px;
    padding-top: 4px;
}

.comment-content {
    margin-left: 45px;
}

.comment-list ul li {
    padding-left: 25px;
}

.comment-reply-form {
    margin-top: 80px;
}

#comment-form {
    margin-top: 35px;
}

#comment-form .form-control:hover {
    border-color: #6CB670;
}

.form-group:last-child,
.form-group:last-child input {
    margin: 0;
}

@media (max-width: 990px) and (min-width:303px) {
    .our-homes-img-sm {
        width: 40% !important;
        display: inline !important;
        margin-top: -10% !important;
    }

    .icon-box {
        margin: 46.19px auto 60px !important;
    }
}

.our-homes-img-m {
    margin-top: -25%;
    width: 125%;
}

.container-rounded-border {
    display: inline-block;
    border: 1px solid #eaeaea;
    padding: 0px 2px !important;
    border-radius: 5px;
    padding: 10px;
}

.highlight {
    color: var(--kalawala-text-gray);
}


