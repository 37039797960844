@use '../../styles/mixins';
@import  '../../styles/styles.scss';

.navigation {
  width: 100%;
  z-index: 99;
  margin-bottom: 0;
  padding:10px;

  &.navbar{
    border-radius: 0;
  }  

  .container {
    margin-right: auto;
    margin-left: auto;
  }
  .navMenu{
    .navText {
      font-size: 16px;
      color: var(--kalawala-text-gray); /* Color del texto principal */
      &.active {
        color: $primary-color;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }
}
.navbar-flag {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  svg {
    width: 32px; /* Adjust the size as needed */
    height: 24px; /* Adjust the height proportionally */
  }
}

@media (max-width: 991.98px) {
  .navbar-flag {
    right: 10px; /* Adjust the position for mobile */
   // margin-top: 25px;
  }
}

