@use '../../../../styles/mixins';
@import '../../../../styles/styles.scss';

.imagesModal{
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  // width: 100%;
  // height: 100%;
  background-color: rgba(0, 0, 0, 0.808);

  .modalImage{
    padding: 10px;
    // max-height: 400px;
  }
  .row{
    height: fit-content;
  }
}