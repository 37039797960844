@use '../../styles/mixins';
@import '../../styles//styles.scss';



body,
html,
#root {
    // height: 100%;
    height: fit-content;
}

.modal-open {
    overflow: hidden;
}

.listingContainer {
    width: 100%;
    height: 100%;



    .row {
        margin: 0;
        // height: 100%;
        height: fit-content;
    }

    .col {
        padding: 0;
        height: fit-content;

    }

    .otherOptions {
        // background-color: aqua;
        height: 100%;
        padding: 0 0 0 20px;
        margin-top: 62px;

        @media (max-width: 1199px) {
            padding: 0 20px;
            margin-top: 0;

        }
    }

    .info {
        // background-color:brown;
        height: 100%;
        padding: 0 20px;

        .heading {
            height: 100px;
            @media (max-width: 992px) {
                height: fit-content;
    
            }
        }

        .title {
            // width: 1200px;
            // height: 113px;
            // left: 360px;
            // top: 11px;
            color: $kalawala-text-gray; 
            font-family: 'sourcesanspro', sans-serif;
            padding: 10px 0 0 0; // font-style: normal;
            margin: 0;
                // font-size: 24px;
                // font-weight: 400;
        }

        .location {
            // width: 1200px;
            // height: 113px;
            // left: 360px;
            // top: 11px;
            color: $kalawala-text-gray;
            font-family: 'sourcesanspro', sans-serif;
            // margin: 0;
            // font-style: normal;
            // font-size: 24px;
            // font-weight: 400;
        }
        
        .button-hold {
            height: 50px;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;
            @media (max-width: 992) {
                height: fit-content;
    
            }
        }

        .pictures {
            height: 400px;
            background-color: $kalawala-text-gray;
            // margin-bottom: 25px;
        }

        .amenaties {
            // height: 140px;
            // background-color: rgb(21, 123, 212);
            margin: 25px 0;

        }

        .description {
            // height: 500px;
            // background-color: rgb(21, 199, 212);
            color:  $kalawala-text-gray;
            font-size: 20px;
            font-family: sans-serif;
        }
    }

    .book {
        // background-color: chartreuse;
        height: 100%;
        padding: 0 20px 0 0;
        margin-top: 105px;
        @media (max-width: 991px) {
            padding: 0 20px;
            margin-top: 10px;
        }
    }

}