@import url('https://fonts.googleapis.com/css?family=Anaheim|Quattrocento+Sans:400,700');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import './variables';
html {
    background-color: #F5F2E9;
    color: #737f8a;
}

body {
    background-color: $kalawala-light-cream !important;
    font-family: $secondary-font;
    color: $kalawala-text-gray;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    font-family: $primary-font;
    color: $kalawala-text-gray !important;
}
p {
    font-family: $primary-font;
}