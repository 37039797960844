@use '../../../styles/mixins';
@import '../../../styles//styles.scss';
/*=================================================================
  Portfolio section
==================================================================*/


.portfolio-filter {
  margin-bottom: 40px;
  text-align: center;

  button {
    border: none;
    outline: none;
    border: 1px solid $border-color;
    background: transparent;
    display: inline-block;
    font-size: 16px;
    padding: 6px 20px;
    font-weight: 700;
    color: #737f8a;
    transition: .3s all;

    &.btn-primary.active {
      border: 1px solid $primary-color;
      color: $primary-color;
      background-color: transparent;
    }
  }

}

.portfolio {
  .border2 {
    border-top: 2px solid rgba(236, 239, 241, 0.07);
    height: 1px;
    margin: 15px auto 0;
    position: relative;
    width: 30%;
  
    &:before {
      background-color: $light;
      content: '';
      height: 6px;
      left: 50%;
      margin-left: -20px;
      position: absolute;
      top: -4px;
      width: 50px;
      background-color: $primary-color;
  
    }
  }
}


.portfolio-items-wrapper {
  .mix {
    padding: 5px;
  }
}

.portfolio-block {
  position: relative;
  margin-bottom: 20px;

  &:before {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: rgb(255, 255, 255);
    opacity: 0;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    position: absolute;
    content: '';
  }

  img {
    width: 100%;
    height: auto;
  }

  &:hover:before {
    opacity: 1;
  }

  &:hover .caption h4 {
    top: 45%;
  }

  &:hover .caption {
    opacity: 1;
    top: 55%;
  }

  .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    z-index: 9;
    transition: all 0.2s ease-in-out;
    text-align: center;

    h4 {
      font-size: 16px;
      font-weight: 600;

      a {
        color: #555;
      }
    }

    .search-icon {
      background: $primary-color;
      width: 50px;
      height: 50px;
      color: $light;
      display: inline-block;
      font-size: 20px;
      border-radius: 30px;

      i {
        line-height: 50px;
      }
    }
  }
}