@use '../../styles/mixins';
@import  '../../styles//styles.scss';


.iconContainer{
    // background-color: rgb(80, 26, 26);
    text-align: center;
    width: 200px;
    height: 100px;
    font-size: 20px;
    color:  $kalawala-text-gray;
    padding: 10px;
    
}